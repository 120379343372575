var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "cyui-layout-wrapper"
  }, [_c('cyui-layout', {
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_vm.isCustomHeader ? _c('cyui-header', {
          attrs: {
            "title": _vm.routeMetaTitle
          },
          on: {
            "go-back": _vm.goBack
          }
        }) : _vm._e(), _c('date-range-picker', {
          attrs: {
            "title": "盘点时间"
          },
          on: {
            "change": _vm.onDateChange
          },
          model: {
            value: _vm.searchKey.times,
            callback: function ($$v) {
              _vm.$set(_vm.searchKey, "times", $$v);
            },
            expression: "searchKey.times"
          }
        })];
      },
      proxy: true
    }, {
      key: "default",
      fn: function () {
        return [_c('div', {
          staticClass: "cyui-scroller-panel"
        }, [_c('cyui-scroller', {
          ref: "scroller",
          staticClass: "wrapper",
          attrs: {
            "scrollbar": _vm.scrollbarConfig,
            "pullDownRefresh": _vm.pullDownRefreshConfig,
            "pullUpLoad": _vm.pullUpLoadConfig,
            "startY": parseInt(_vm.startY)
          },
          on: {
            "pullingDown": _vm.handlePullingDown,
            "pullingUp": _vm.handlePullingUp
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function () {
              return [_c('div', {
                staticClass: "assets-list"
              }, _vm._l(_vm.listData, function (item, index) {
                return _c('div', {
                  key: index,
                  staticClass: "assets-card",
                  on: {
                    "click": function ($event) {
                      return _vm.goDetail(item);
                    }
                  }
                }, [_c('div', {
                  staticClass: "row"
                }, [_c('div', {
                  staticClass: "label"
                }, [_vm._v("标题")]), _c('div', {
                  staticClass: "value"
                }, [_vm._v(_vm._s(item.title || '-'))])]), _c('div', {
                  staticClass: "row"
                }, [_c('div', {
                  staticClass: "label"
                }, [_vm._v("盘点时间")]), _c('div', {
                  staticClass: "value"
                }, [_vm._v(_vm._s(item.createTime))])]), _c('div', {
                  staticClass: "row"
                }, [_c('div', {
                  staticClass: "label"
                }, [_vm._v("盘点区域")]), _c('div', {
                  staticClass: "value"
                }, [_vm._v(_vm._s(item.spaceName || '-'))])]), _c('div', {
                  staticClass: "row"
                }, [_c('div', {
                  staticClass: "label"
                }, [_vm._v("盘点人")]), _c('div', {
                  staticClass: "value"
                }, [_vm._v(_vm._s(item.checkPerson || '-'))])]), _c('div', {
                  staticClass: "row"
                }, [_c('div', {
                  staticClass: "label"
                }, [_vm._v("状态")]), _c('div', {
                  staticClass: "value status"
                }, [item.status ? _c('span', [_vm._v("已完成")]) : _c('span', [_vm._v("进行中")])])])]);
              }), 0)];
            },
            proxy: true
          }])
        })], 1), _vm.listData.length === 0 && _vm.isLoading === false ? _c('no-data', {
          attrs: {
            "msg": _vm.noDataMessage
          },
          scopedSlots: _vm._u([{
            key: "btn",
            fn: function () {
              return [_vm.isRefreshPage ? _c('div', {
                staticClass: "cyui-btn cyui-btn-default cyui-btn-inline cyui-btn-radius",
                on: {
                  "click": _vm.handleRefreshPage
                }
              }, [_vm._v(" 刷新重试 ")]) : _vm._e()];
            },
            proxy: true
          }], null, false, 3275925837)
        }) : _vm._e()];
      },
      proxy: true
    }])
  })], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };